::-webkit-scrollbar {
    width: 0.625rem;
    height: 0.313rem;
 }
 
 ::-webkit-scrollbar-thumb {   
   border-radius: 30px;
   background: #a28a6b;
   box-shadow: inset 2px 2px 2px rgba(255,255,255,.25), inset -2px -2px 2px rgba(0,0,0,.25);}
 
 ::-webkit-scrollbar-track {     
   background-color: #fff;
   border-radius:0.625rem;
   /* background: linear-gradient(to right,#201c29,#201c29 1px,#100e17 1px,#100e17); */
 }
